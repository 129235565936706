export const setorOptions = {
  'Setor de Fiscalização': [
    'Auto de infração', 'Notificação/Advertência'
  ],
  'Setor Jurídico': [
    'Termo de acordo e compromisso', 'Declaração de regularidade'
  ],
  'Setor Administrativo': [
    'CERTIDÃO DE USO E OCUPAÇÃO DO SOLO',
    'CERTIDÃO DE DISPENSA DE LICENCIAMENTO AMBIENTAL',
    'LICENÇA PRÉVIA - LP',
    'LICENÇA DE INSTALAÇÃO – LI',
    'LICENÇA DE FUNCIONAMENTO - LF',
    'LICENÇA AMBIENTAL SIMPLIFICADA – LAS',
    'LICENÇA MUNICIPAL PARA EXTRAÇÃO DE MINÉRIO',
    'LICENÇA DE EXPLORAÇÃO FLORESTAL – LEF',
    'REGISTRO DE LICENCIAMENTO AMBIENTAL',
    'DECLARAÇÃO DE INEXIGIBILIDADE AMBIENTAL',
    'AUTORIZAÇÃO AMBIENTAL (ESPECIFICAR QUAL SERÁ)',
    'LICENÇA AMBIENTAL DE AMPLIAÇÃO OU ALTERAÇÃO – LAA',
    'LICENÇA AMBIENTAL POR ADESÃO E COMPROMISSO – LAC',
    'LICENÇA AMBIENTAL ÚNICA – LAU',
    'LICENÇA AMBIENTAL CORRETIVA – LC',
    'LICENÇA AMBIENTAL PARA PSICULTURA',
    'PARECER TÉCNICO / RELATÓRIO TÉCNICO',
    'OUTRA (S) ATIVIDADE (S)'

  ],
  'Ministério Público': [
    'Analista/Equipe de fiscalização'
  ],
  'Setor Técnico': [
    'Exigências'
  ]
}