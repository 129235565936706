import { CheckIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Center, HStack } from '@chakra-ui/layout';
import { TemplateHandler } from 'easy-template-x';
import apiCnpj from '../../api/apiCnpj';
import moment from 'moment';
import {
  Box, Button, Checkbox, Divider, Heading, IconButton,
  Image, Input, InputGroup, InputLeftAddon, Radio,
  RadioGroup, Select, Stack, Text, Textarea, useToast, VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import apiCep from '../../api/apiCep';
import prefeituraLogo from '../../assets/prefeituraLogo.png';
import Navigation from '../../Components/Nabvigation';
import { licensesInfo } from './constants';

const types = [
  {value:'LAP', label:'Licença ambiental Prévia'},
  {value:'LAC', label:'Licença ambiental Corretiva'},
  {value:'LAU', label:'Licença ambiental Única'},
  {value:'LAPS', label:'Licença ambiental Piscicultura'},
  {value:'LAA', label:'Licença ambiental de Ampliação ou Alteração'},
  {value:'LAAC', label:'Licença Ambiental por Adesão e Compromisso'},
  {value:'LAS', label:'Licença Ambiental Simplificado'},
  {value:'LAI', label:'Licença Ambiental de Instalação'},
  {value:'LAF', label:'Licença Ambiental de Funcionamento'},
  {value:'LAF', label:'Licença de Exploração Florestal'},
  {value:'LMEM', label:'Licença Municipal para Extração de Minério'},
  {value:'CDL', label:'Certidão de Dispensa de Licença'},
  {value:'CUOS', label:'Certidão de Uso e Ocupação do Solo'},
  {value:'AAM', label:'Autorização Ambiental Municipal'},
  {value:'ALPSRL', label:'Autorização para Limpeza de pastagem sem rendimento lenhoso'},
  {value:'APEA', label:'Autorização para Poda ou erradicação de árvore'},
  {value:'RLA', label:'Registro de Licenciamento/Atividade'},
  {value:'MEI', label:'MEI – Micro empreendedor individual'},
];

const ref = React.createRef();

moment.locale('pt');
moment.updateLocale('pt', {
  months : [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

const AddLicense = () => {
  const toast = useToast();
  const year = moment().get('year');
  const nextYear = moment().add(1, 'year').format('YYYY');
  const month = moment().format('MMMM');
  const day = moment().get('date');
  const [documentType, setDocumentType] = useState(undefined);
  const [processNumber, setProcessNumber] = useState('');
  const [dataType, setDataType] = useState('cpf');
  const [identification, setIdentification] = useState('');
  const [description, setDescription] = useState();
  const [tecnicalAnotations, setTecnicalAnotations] = useState();
  const [cep, setCep] = useState();
  const [cepProperty, setCepProperty] = useState('');
  const [nomeRequerente, setNomeRequerente] = useState('');
  const [cidadeRequerente, setCidadeRequerente] = useState('');
  const [bairroRequerente, setBairroRequerente] = useState('');
  const [ufRequerente, setUfRequerente] = useState('');
  const [ruaRequerente, setRuaRequerente] = useState('');
  const [cidadePropriedade, setCidadePropriedade] = useState('');
  const [bairroPropriedade, setBairroPropriedade] = useState('');
  const [ufPropriedade, setUfPropriedade] = useState('');
  const [ruaPropriedade, setRuaPropriedade] = useState('');
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [atividadePrincipal, setAtividadePrincipal] = useState('');
  const [cnae, setCnae] = useState('');
  const [totalArea, setTotalArea] = useState('');
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [zone, setZone] = useState('');
  const [nomeElaborador, setNomeElaborador] = useState('');
  const [matriculaElaborador, setMatriculaElaborador] = useState('');
  const [list, setList] = useState([
    {id: 1, isChecked: true, deletable: false, description: 'A presente certidão de uso e ocupação do solo está sendo concedido com base nas informações constantes no Processo Administrativo n 2021031654, e não dispensa e nem subsitui outros, outros alvarás ou certidões exigidas pelas legislações ambientais vigentes;'},
    {id: 2, isChecked: true, deletable: false, description: 'A SEMARH/LUZ deverá ser comunicada imediatamente em caso de acidentes que envolvam o Meio Ambiente;'},
    {id: 3, isChecked: true, deletable: false, description: 'A instalação do empreendimento não poderá causar transtornos ao meio ambiente e/ou a terceiros fora da área de sua propriedade ou fora dela;'},
    {id: 4, isChecked: true, deletable: false, description: 'A SEMARH/LUZ reserva-se no direito de revogar a presente licença no caso de descumprimento descondicionantes, ou de qualquer dispositivo que fira a Legislação Ambiental vigente, assim como a omissão ou falsa descrição de informações relevantes que subsidiam a sua expedição, ou superveniência deste órgão.'},
  ]);

  async function getTemplate() {
    const request = await fetch(licensesInfo[documentType].file);
    const defaultTemplate = await request.blob();
    console.log(defaultTemplate);
    return defaultTemplate;
  };
  
  function saveFile(filename, blob) {
    // get downloadable url from the blob
    const blobUrl = URL.createObjectURL(blob);
  
    // create temp link element
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;
  
    // use the link to invoke a download
    document.body.appendChild(link);
    link.click();
  
    // remove the link
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  const getLicenseTypeName = () => {
    const goal = types.filter( function(item){return (item.value === documentType);})
    return goal[0].label;
  }

  const handleCheckZipCode = async (cep, index) => {
    setLoadingZipCode(true);
    await apiCep.get(`/${cep}/json`)
    .then((response) => {
      if (index === 1) {
        setCidadeRequerente(response.data.localidade);
        setBairroRequerente(response.data.bairro);
        setRuaRequerente(response.data.logradouro);
        setUfRequerente(response.data.uf);
      } else {
        setCidadePropriedade(response.data.localidade);
        setBairroPropriedade(response.data.bairro);
        setRuaPropriedade(response.data.logradouro);
        setUfPropriedade(response.data.uf);
      }
      
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
      toast({
        title: "Erro ao consultar CEP",
        description: "O serviço pode estar fora do ar, aguarde alguns minutos",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });

    setLoadingZipCode(false);
  };

  const handleCheckCnpj = async () => {
    const _cnpj = identification.replace(/\D/g, '');
    setLoadingCnpj(true);
    apiCnpj.get(`/${encodeURI(_cnpj)}`)
    .then((response) => {
      console.log(response);
      setNomeRequerente(response.data.nome);
      setCep(response.data.cep.replace(/\D/g, ''));
      setCidadeRequerente(response.data.municipio);
      setBairroRequerente(response.data.bairro);
      setRuaRequerente(response.data.logradouro);
      setUfRequerente(response.data.uf);
      setAtividadePrincipal(response.data.atividade_principal[0].text);
      setCnae(response.data.atividade_principal[0].code);
    })
    .catch((err) => {
      console.log("ops! ocorreu um erro" + err);
      setLoadingCnpj(false);
      toast({
        title: "Erro ao consultar CNPJ",
        description: "O serviço pode estar fora do ar, aguarde alguns minutos",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
    setLoadingCnpj(false);
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    setDescription(inputValue)
  };

  const handleInputChangeAnotations = (e) => {
    let inputValue = e.target.value
    setTecnicalAnotations(inputValue)
  };

  const generateFile = async () => {
    setFileLoading(true);
    try {
      // 1. read template file
      const templateFile = await getTemplate();
  
      // 2. read json data
      const data = {
        pNumber: processNumber,
        year: year,
        nextYear: nextYear,
        month: month,
        day: day,
        description: description,
        identification: identification,
        nomeRequerente: nomeRequerente.toUpperCase(),
        ruaRequerente: ruaRequerente.toUpperCase(),
        bairroRequerente: bairroRequerente.toUpperCase(),
        cidadeRequerente: cidadeRequerente.toUpperCase(),
        ufRequerente: ufRequerente.toUpperCase(),
        cepRequerente: cep,
        nomeElaborador: nomeElaborador.toUpperCase(),
        matriculaElaborador: matriculaElaborador,
        exigencias: list,
        atividadePrincipal: atividadePrincipal.toUpperCase(),
        cnae: cnae,
        zone: zone.toUpperCase(),
        cepProperty: cepProperty,
        ufPropriedade: ufPropriedade.toUpperCase(),
        cidadePropriedade: cidadePropriedade.toUpperCase(),
        bairroPropriedade: bairroPropriedade.toUpperCase(),
        ruaPropriedade: ruaPropriedade.toUpperCase(),
      }
      console.log(data);
      // 3. process the template
      const handler = new TemplateHandler();
      const docx = await handler.process(templateFile, data);
  
      // 4. save output
      saveFile("result.docx", docx);
      setFileLoading(false);
    } catch (e) {
      // error handling
      setFileLoading(false);
      console.error(e);
    }
  };

  const addNewItem = () => {
    const transformed = [...list];
    transformed.push({id: list.length + 1, isChecked: true, deletable: true, description: ''});
    setList(transformed);
  };

  const removeItem = (index) => {
    const transformed = [...list];
    transformed.splice(index, 1);
    setList(transformed);
  };

  const handleItemDescription = (index, description) => {
    console.log(description);
    let transformed = [...list];
    transformed[index].description = description;
    setList(transformed);
  }

  useEffect(() => {
    if (documentType !== undefined) {
      setDescription(licensesInfo[documentType].description);
      setList(licensesInfo[documentType].obsList);
    };
  }, [documentType])

  useEffect(() => {
    console.log(cidadeRequerente)
  }, [cidadeRequerente])

  return (
    <>
      <Navigation />
      <Center marginTop="10px">
        <Heading size="lg">Adicionar nova licença</Heading>
      </Center>
      <Box padding="15px">
        <Divider size="2px" />
        <Box marginTop="20px" width="100%">
          <Box width="50%">
            <Text fontSize="xl" marginBottom="5px">Tipo de documento</Text>
            <Select 
              placeholder="Selecione o tipo de documento" 
              size="md"
              onChange={(e) => {
                setDocumentType(e.target.value)
              }}
            >
              <option value="CUOS">Certidão de Uso e Ocupação do Solo</option>
              <option value="LAP">Licença ambiental Prévia</option>
              {/* <option value="LAC">Licença ambiental Corretiva</option>
              <option value="LAU">Licença ambiental Única</option>
              <option value="LAPS">Licença ambiental Piscicultura</option>
              <option value="LAA">Licença ambiental de Ampliação ou Alteração</option>
              <option value="LAAC">Licença Ambiental por Adesão e Compromisso</option>
              <option value="LAS">Licença Ambiental Simplificado</option>
              <option value="LAI">Licença Ambiental de Instalação</option>
              <option value="LAF">Licença Ambiental de Funcionamento</option>
              <option value="LEF">Licença de Exploração Florestal</option>
              <option value="LMEM">Licença Municipal para Extração de Minério</option>
              <option value="CDL">Certidão de Dispensa de Licença</option>
              <option value="AAM">Autorização Ambiental Municipal</option>
              <option value="ALPSRL">Autorização para Limpeza de pastagem sem rendimento lenhoso</option>
              <option value="APEA">Autorização para Poda ou erradicação de árvore</option>
              <option value="RLA">Registro de Licenciamento/Atividade</option>
              <option value="MEI">MEI – Micro empreendedor individual</option> */}
            </Select>
          </Box>
          {documentType !== undefined && (
            <>
              <Box marginTop="10px" width="50%">
                <Text>Número do processo</Text>
                <InputGroup>
                  <InputLeftAddon children="Nº processo" />
                  <Input type="tel" value={processNumber} placeholder="XXXXXXXXXX" onChange={(e) => setProcessNumber(e.target.value)}/>
                </InputGroup>
                <Text marginTop="10px">Descrição</Text>
                <Textarea
                  value={description}
                  onChange={handleInputChange}
                  placeholder="Descrição"
                  size="md"
                  minHeight="120px"
                />
                <Divider size="2px" marginTop="20px"/>
              </Box>
              <Box display="flex" flexDirection="row"  spacing="15px">
                <Box width="50%">
                  <Heading marginTop="10px" size="md">
                    1. Dados do Requerente:
                  </Heading>
                  <Box justifyContent="flex-start" display="flex" flexDirection="column">
                    <RadioGroup marginTop="10px" marginBottom="10px" onChange={setDataType} value={dataType}>
                      <Stack spacing={4} direction='row'>
                        <Radio colorScheme='teal' value='cpf'>CPF</Radio>
                        <Radio colorScheme='teal' value='cnpj'>CNPJ</Radio>
                      </Stack>
                    </RadioGroup>
                    <Box display="flex" flexDirection="row" float="left" marginBottom="8px">
                      <InputGroup marginBottom="8px" maxWidth="300px" marginRight="10px">
                        <InputLeftAddon children={dataType === 'cpf' ? 'CPF' : 'CNPJ'} />
                        {dataType === 'cpf' ? (
                          <Input type="cpf" value={identification} onChange={(e) => setIdentification(e.target.value)} />
                        ) : (
                          <Input 
                            as={InputMask} 
                            mask="**.***.***/****-**"
                            type="cnpj" 
                            value={identification} 
                            onChange={(e) => setIdentification(e.target.value)} 
                          />
                        )}
                        
                      </InputGroup>
                      <IconButton
                        marginTop="5px"
                        variant='outline'
                        size="sm"
                        colorScheme='teal'
                        aria-label='Set Date'
                        icon={<CheckIcon />}
                        isLoading={loadingCnpj}
                        disabled={dataType === 'cpf'}
                        onClick={() => handleCheckCnpj()}
                      />
                    </Box>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children={dataType === 'cpf' ? 'Nome' : 'Razão Social'} />
                      <Input type="name" value={nomeRequerente} onChange={(e) => setNomeRequerente(e.target.value)} />
                    </InputGroup>
                    
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children={dataType === 'cpf' ? 'RG' : 'Inscr. Estadual'} />
                      <Input type="rg" placeholder="" />
                    </InputGroup>
                    <Box display="flex" flexDirection="row" float="left" marginBottom="8px">
                      <InputGroup maxWidth="250px" marginRight="10px">
                        <InputLeftAddon children="CEP" />
                        <Input id="cep" value={cep} type="number" onChange={(e) => setCep(e.target.value)} />
                      </InputGroup>
                      <IconButton
                        marginTop="5px"
                        variant='outline'
                        size="sm"
                        colorScheme='teal'
                        aria-label='Set Date'
                        icon={<CheckIcon />}
                        isLoading={loadingZipCode}
                        onClick={() => handleCheckZipCode(cep, 1)}
                      />
                    </Box>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="Rua" />
                      <Input value={ruaRequerente} id="rua" type="cep" placeholder="" onChange={(e) => setRuaRequerente(e.target.value)}/>
                    </InputGroup>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="Bairro" />
                      <Input value={bairroRequerente} id="bairro" type="tel" placeholder="" onChange={(e) => setBairroRequerente(e.target.value)}/>
                    </InputGroup>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="Municipio" />
                      <Input value={cidadeRequerente} id="cidade" type="tel" placeholder="" onChange={(e) => setCidadeRequerente(e.target.value)}/>
                    </InputGroup>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="UF" />
                      <Input value={ufRequerente} id="cidade" type="tel" placeholder="" onChange={(e) => setUfRequerente(e.target.value)}/>
                    </InputGroup>
                  </Box>
                  <Divider size="2px" marginTop="20px"/>
                  <Heading size="md">
                    2. Dados da Atividade:
                  </Heading>
                  <VStack spacing="8px">
                    <InputGroup>
                      <InputLeftAddon children="Atividade Licenciada" />
                      <Input type="atividade" value={atividadePrincipal} onChange={(e) => setAtividadePrincipal(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                      <InputLeftAddon children="CNAE" />
                      <Input type="cnae" value={cnae} onChange={(e) => setCnae(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                      <InputLeftAddon children="Zona de inserimento"/>
                      <Input type="tel" placeholder="" value={zone} onChange={(e) => setZone(e.target.value)}/>
                    </InputGroup>
                    {licensesInfo[documentType].areaFields && (
                      <>
                        <InputGroup>
                          <InputLeftAddon children="Área Total construída" />
                          <Input type="area" placeholder="" />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon children="Área Total da Atividade" />
                          <Input type="areaT" placeholder="" />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon children="Coordenadas Geográficas" />
                          <Input type="tel" placeholder="" />
                        </InputGroup>
                      </>
                    )}
                    <InputGroup>
                      <InputLeftAddon children="Elaboração SEMARH/LUZ por " />
                      <Input type="elab" value={nomeElaborador} onChange={(e) => setNomeElaborador(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                      <InputLeftAddon children="Matrícula Por" />
                      <Input type="tel" placeholder="" value={matriculaElaborador} onChange={(e) => setMatriculaElaborador(e.target.value)}/>
                    </InputGroup>
                  </VStack>
                  <Divider size="2px" marginTop="20px"/>
                  <Heading size="md">
                    3. Local da Atividade:
                  </Heading>
                  <VStack justify="flex-start" spacing="8px">
                    <Box display="flex" flexDirection="row" float="left" marginBottom="8px">
                      <InputGroup maxWidth="250px" marginRight="10px">
                        <InputLeftAddon children="CEP" />
                        <Input id="cep" value={cepProperty} type="number" onChange={(e) => setCepProperty(e.target.value)} />
                      </InputGroup>
                      <IconButton
                        marginTop="5px"
                        variant='outline'
                        size="sm"
                        colorScheme='teal'
                        aria-label='Set Date'
                        icon={<CheckIcon />}
                        isLoading={loadingZipCode}
                        onClick={() => handleCheckZipCode(cepProperty, 2)}
                      />
                    </Box>
                    <InputGroup>
                      <InputLeftAddon children="Local/Endereço" />
                      <Input value={ruaPropriedade} type="local" placeholder="" onChange={(e) => setRuaPropriedade(e.target.value)}/>
                    </InputGroup>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="Bairro" />
                      <Input value={bairroPropriedade} id="bairro" type="tel" placeholder="" onChange={(e) => setBairroPropriedade(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                      <InputLeftAddon children="Município" />
                      <Input value={cidadePropriedade} type="cidade" placeholder="" onChange={(e) => setCidadePropriedade(e.target.value)}/>
                    </InputGroup>
                    <InputGroup marginBottom="8px">
                      <InputLeftAddon children="Estado" />
                      <Input value={ufPropriedade} id="estado" type="tel" placeholder="" onChange={(e) => setUfPropriedade(e.target.value)}/>
                    </InputGroup>

                    {licensesInfo[documentType].areaFields && (
                      <>
                        <InputGroup>
                          <InputLeftAddon children="Número do recibo do CAR" />
                          <Input type="rg" placeholder="" />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon children="Área total do imóvel (m2)" />
                          <Input value={totalArea} type="cep" placeholder="" onChange={(e) => setTotalArea(e.target.value)}/>
                        </InputGroup>
                      </>
                    )}
                    
                    <InputGroup>
                      <InputLeftAddon children="Matrícula/Registro" />
                      <Input type="tel" placeholder="" />
                    </InputGroup>
                  </VStack>
                  <Divider size="2px" marginTop="20px"/>
                  <Heading marginTop="10px" size="md">
                    4. Exigências Técnicas - Observações:
                  </Heading>
                  <Box marginTop="8px">
                    <Text marginTop="10px">Exigências Técnicas - Observações:</Text>
                    {list && list.map((item, index) => (
                      <Box marginTop="10px">
                        <HStack>
                          <Checkbox isChecked={item.isChecked}>{`4.${index + 1}`}</Checkbox>
                          <Textarea
                            value={item.description}
                            onChange={(e) => handleItemDescription(index, e.target.value)}
                            size='sm'
                          />
                          <IconButton 
                            aria-label='delete exigencia'
                            variant='outline'
                            size="sm"
                            colorScheme='teal'
                            icon={<DeleteIcon />}
                            isDisabled={!item.deletable}
                            onClick={() => removeItem(index)}
                          />
                        </HStack>
                        
                        <Divider size="2px" marginTop="5px" marginBottom="5px"/>
                      </Box>
                    ))}
                    <IconButton 
                      aria-label='Add exigencia'
                      variant='outline'
                      size="sm"
                      colorScheme='teal'
                      icon={<AddIcon />}
                      onClick={() => addNewItem()}
                    />
                  </Box>
                </Box>
                <Box 
                  padding="10px"
                  borderWidth="1px"
                  borderColor="#96bfbf"
                  marginLeft="10px"
                  width="50%"
                  ref={ref}
                >
                  <Center>
                    <HStack>
                      <Image
                        boxSize='150px'
                        objectFit='cover'
                        src={prefeituraLogo}
                        alt="logoPrefeitura"
                      />
                    </HStack>
                  </Center>
                  <Box backgroundColor="#e2e0e0" height="25px" width="100%" marginBottom="5px">
                    <Center>
                      <Text casing="uppercase">{getLicenseTypeName()}</Text>
                    </Center>
                  </Box>
                  <Box backgroundColor="#e2e0e0" height="20px" width="100%" marginBottom="5px">
                    <Text fontSize='md' casing="uppercase">{`Número do processo: ${processNumber}`}</Text>
                  </Box>
                  <Text marginBottom="5px">{description}</Text>
                  <Box backgroundColor="#e2e0e0" height="20px" width="100%" marginBottom="5px">
                    <Text fontSize='md' casing="uppercase">{`1. Nome/Razão Social: ${nomeRequerente}`}</Text>
                  </Box>
                  <Text fontSize="sm">{`1.1 Endereço: ${ruaRequerente}, ${bairroRequerente} - ${cidadeRequerente}/${ufRequerente}`}</Text>
                  <Text fontSize="sm">{`1.2 CEP: ${cep}`}</Text>
                  <Text fontSize="sm">{`1.3 CPF/CNPJ: ${identification}`}</Text>
                  <Text fontSize="sm">{`1.4 Atividade Principal: ${cnae} - ${atividadePrincipal}`}</Text>

                  <Box backgroundColor="#e2e0e0" height="20px" width="100%" marginBottom="5px" marginTop="10px">
                    <Text fontSize='md' casing="uppercase">{`2. Atividade Licenciada: ${cnae} - ${atividadePrincipal}`}</Text>
                  </Box>
                  <Text fontSize="sm">{`2.1 Local da Atividade: ${ruaPropriedade}, ${bairroPropriedade} - ${cidadePropriedade}/${ufPropriedade}`}</Text>
                  <Text fontSize="sm">{`2.2 Área total: ${totalArea} m2`} </Text>

                </Box>
              </Box>
              
              <Center marginTop="20px">
                <Button
                  size="sm"
                  colorScheme='teal'
                  variant='solid'
                  marginRight="50px"
                  onClick={() => generateFile()}
                  isLoading={fileLoading}
                >
                  Criar Licença
                </Button>
              </Center>
              
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default AddLicense;