import firebase from 'firebase';
import 'firebase/functions';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERI,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

const Firebase = firebase.initializeApp(config);

export const fns = firebase.functions();
export default Firebase;
export const db = Firebase.firestore();
export const auth = Firebase.auth();