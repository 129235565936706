import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import Login from './pages/Login';
import Home from './pages/Home';
import AddLicense from './pages/AddLicense';
import Licenses from './pages/Licenses';

function App() {
  return (
    <Router>
      <AuthProvider>
      <ReduxProvider store={store}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/adicionarLicenca" component={AddLicense} />
            <Route exact path="/licencas" component={Licenses} />
            <Route path="/login" component={Login} />
          </Switch>
        </ReduxProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
