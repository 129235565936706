import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Center } from '@chakra-ui/layout';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../store/sla/actions';
import { db } from '../../config/firebase'
import {
  Heading,
  Box,
  Input,
  Button,
  Divider,
  Text,
  RadioGroup,
  Radio,
  Stack,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Tooltip,
  Skeleton,
  VStack
} from '@chakra-ui/react';
import { AddIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons'
import { useAuth } from '../../contexts/Auth';
import Navigation from '../../Components/Nabvigation';
import EventItem from '../../Components/EventItem';
import { isEmpty } from 'lodash';
import AddEventModal from '../../Components/AddEventModal/AddEventModal';
import { setorOptions } from '../../utils/setorOptions';

const Home = () => {
  const { currentUser } = useAuth();
  //const prevDataRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, userList } = useSelector((state) => {
    return state.slaReducer;
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterCalendar, setFilterCalendar] = useState('month');
  
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const licenseNumberRef = useRef();
  
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [itemToEdit, setItemToEdit] = useState(undefined);
  const [openHistory, setOpenHistory] = useState(false);
  const [eventHistory, setEventHistory] = useState();

  const handleDelete = (id) => {
    setLocalLoading(true);
    db.collection('events').doc(id).delete();
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const handleEdit = (item) => {
    setItemToEdit(item);
    onOpen();
  };

  useEffect(() => {
    dispatch(Creators.getUsers());
  }, [dispatch])

  useEffect(() => {
    setLocalLoading(true)
    const response = db.collection('events')
      .where('timestamp', '<', moment(endDate).valueOf())
      .where('timestamp', '>', moment(startDate).valueOf())
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let myArray = [];
          snapshot.forEach(doc => myArray.push(doc.data()))
          setFilteredEvents(myArray);
          setTimeout(() => {
            setLocalLoading(false);
          }, 3000)
          
        } else {
          setFilteredEvents([]);
          setLocalLoading(false);
        }
      })
      return () => {
        response()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getUserName = (responsibleId) => {
    return function(value) {
      return value.id === responsibleId;
    }
  }

  const handleSend = (item, idToEdit, toSection, toType, responsibleId) => {
    const responsibleName = userList.filter(getUserName(responsibleId));
    db.collection('events').doc(idToEdit).set({
      currentResponsibleId: responsibleId,
      eventSection: toSection,
      eventType: toType,
      currentResponsibleName: responsibleName[0].name,
      history: item.history
    }, { merge: true });
  };

  const handleHistory = (history) => {
    setEventHistory(history);
    setOpenHistory(true);
  };

  const handleFilterByNumber = async () => {
    setLocalLoading(true);
    if (!isEmpty(licenseNumberRef.current.value)) {
      const response = await db.collection('events')
      .where('licenseNumber', '==', licenseNumberRef.current.value)
      .get().then(query => {
        const data = query.docs.map(doc => doc.data());
        return data;
      })
      .catch(function(error){
        console.error("Error adding document: ", error);
      })
      setFilteredEvents(response);
      setLocalLoading(false)
    } else {
      handleCleanFilter();
    }
  };

  const handleCleanFilter = async () => {
    licenseNumberRef.current.value = '';
    setLocalLoading(true);
    const response = await db.collection('events')
      .where('timestamp', '<', moment(endDate).valueOf())
      .where('timestamp', '>', moment(startDate).valueOf())
      .get().then(query => {
        const data = query.docs.map(doc => doc.data());
        return data;
      })
      .catch(function(error){
        console.error("Error adding document: ", error);
      })
    setLocalLoading(false)
    setFilteredEvents(response);
  };

  useEffect(() => {
    if (isEmpty(currentUser)) {
      history.push('/login');
    } else {
      const userDb = db.collection('users').where("id", "==", currentUser.uid)
        .get()
        .then(query => {
        const data = query.docs.map(doc => doc.data());
        return data;
        })
        .catch(function(error){
          console.error("Error adding document: ", error);
        });
        if (userDb !== undefined) {
          userDb.then(data => {
            sessionStorage.setItem('user-role', data[0].role)
            sessionStorage.setItem('user-name', data[0].name)
            sessionStorage.setItem('user-id', data[0].id)
          });
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    if (filterCalendar === 'thirtydays') {
      setLocalLoading(true);
      const goalDate = moment().add(30, 'days');
      const fromDate = moment();
      setStartDate(fromDate);
      setEndDate(goalDate);
      setTimeout(() => {
        setLocalLoading(false);
      }, 2000);
    }
    if (filterCalendar === 'day') {
      setLocalLoading(true);
      const today = moment();
      setStartDate(today);
      setEndDate(today);
      setTimeout(() => {
        setLocalLoading(false);
      }, 2000);
    }
    if (filterCalendar === 'week') {
      setLocalLoading(true);
      const fromDate = moment().startOf('week');
      const toDate = moment().endOf('week');
      setStartDate(fromDate);
      setEndDate(toDate);
      setTimeout(() => {
        setLocalLoading(false);
      }, 2000);
    }
    if (filterCalendar === 'month') {
      setLocalLoading(true);
      const fromDate = moment().startOf('month');
      const toDate = moment().endOf('month');
      setStartDate(fromDate);
      setEndDate(toDate);
      setTimeout(() => {
        setLocalLoading(false);
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCalendar]); */

  return (
    <>
      <Navigation />
      <Center marginTop="10px">
        <Heading size="lg">Agenda</Heading>
      </Center>
      <Box 
        margin="20px"
        minHeight="400px"
        padding="10px"
        borderWidth="1px" 
        borderRadius="lg"
        display="flex"
        flexDirection="column"
      >
        <Box 
          display="flex"
          flexDirection="row"
          height="30px"
          marginTop="10px"
          paddingLeft="20px"
          paddingRight="20px"
          marginBottom="20px"
        >
          <Button
            size="sm"
            leftIcon={<AddIcon />}
            colorScheme='teal'
            variant='solid'
            marginRight="50px"
            onClick={() => {
              setItemToEdit(undefined)
              onOpen()
            }}
          >
            Novo Evento
          </Button>
          <RadioGroup onChange={setFilterCalendar} value={filterCalendar}>
            <Stack spacing={4} direction='row'>
              <Radio colorScheme='teal' value='day'>Dia</Radio>
              <Radio colorScheme='teal' value='week'>Semana</Radio>
              <Radio colorScheme='teal' value='month'>Mês</Radio>
              <Radio colorScheme='teal' value='thirtydays'>Em 30 dias</Radio>
            </Stack>
          </RadioGroup>
          <Stack direction='row' marginLeft="20px">
            <Divider marginRight="20px" orientation='vertical' />
            <IconButton
              colorScheme='teal'
              aria-label='previous'
              size='xs'
              icon={<ArrowLeftIcon />}
              onClick={() => {
                const fakeToday = moment(startDate).subtract(10, 'days').toISOString();
                const fromDate = moment(fakeToday).startOf('month');
                const toDate = moment(fakeToday).endOf('month');
                setStartDate(fromDate);
                setEndDate(toDate);
              }}
            />
            
            <Text fontSize='md'>
              {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
            </Text>

            <IconButton
              colorScheme='teal'
              aria-label='next'
              size='xs'
              icon={<ArrowRightIcon />}
              onClick={() => {
                const fakeToday = moment(endDate).add(10, 'days').toISOString();
                const fromDate = moment(fakeToday).startOf('month');
                const toDate = moment(fakeToday).endOf('month');
                setStartDate(fromDate);
                setEndDate(toDate);
              }}
              marginRight="20px"
            />
           
          </Stack>
          <Divider marginLeft="20px" marginRight="20px" orientation='vertical' />
          <Box display="flex" alignItems="center">
            <Text marginRight="10px">Busca:</Text>
            <Input
              ref={licenseNumberRef}
              placeholder='N do processo'
              maxWidth="200px"
            />
            <Tooltip hasArrow label='Filtrar' bg='gray.300' color='black'>
              <IconButton
                variant='outline'
                size="sm"
                marginLeft="10px"
                colorScheme='teal'
                aria-label='Set Date'
                icon={<CheckIcon />}
                onClick={() => {
                  handleFilterByNumber()
                }}
              />
            </Tooltip>
            <Tooltip hasArrow label='Limpar filtro' bg='gray.300' color='black'>
              <IconButton
                variant='outline'
                size="sm"
                marginLeft="10px"
                colorScheme='teal'
                aria-label='Set Date'
                icon={<CloseIcon />}
                onClick={() => {
                  handleCleanFilter()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          {localLoading && (
            <VStack>
              <Skeleton w="100%" height="174px" />
              <Skeleton w="100%" height="174px" />
              <Skeleton w="100%" height="174px" />
            </VStack>
          )}
          {!localLoading && isEmpty(filteredEvents) && (
            <Box marginTop="150px" display="flex" justifyContent="center" alignItems="center">
              <Text>Nenhum evento para as datas selecionadas</Text>
            </Box>
          )}
          {!localLoading && filteredEvents && filteredEvents.map((item) => (
            <EventItem
              item={item}
              currentUser={currentUser}
              key={Math.random()}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              setorOptions={setorOptions}
              userList={userList}
              handleSend={handleSend}
              handleHistory={handleHistory}
            />
          ))}
        </Box>
      </Box>
      <AddEventModal 
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        currentUser={currentUser}
        itemToEdit={itemToEdit}
      />
      {/* <Modal isOpen={true} onClose={() => console.log('teste')}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alerta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Licensa do sistema expirada. Contate o Administrador.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme='teal' mr={3} onClick={() => (false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
      <Modal isOpen={openHistory} onClose={() => setOpenHistory(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Histórico</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <List spacing={3}>
            {eventHistory && eventHistory.map((item) => (
              <ListItem key={Math.random()}>
                <Box
                  padding="10px"
                  borderWidth="1px" 
                  borderRadius="lg"
                  width="100%"
                  borderColor="#96bfbf"
                  marginBottom="10px"
                  shadow='md'
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  cursor="pointer"
                >
                  <ListIcon as={ArrowForwardIcon} color='green.500' />
                  <Box
                    borderLeft="1px"
                    minHeight="30px"
                    display="flex"
                    flexDirection="column"
                    borderColor="#96bfbf"
                    paddingLeft="10px"
                  >
                    <Heading size="sm">{moment(item.date).format('DD/MM/YYYY - HH:mm:ss')}</Heading>
                    <Text fontSize="sm">{item.section}</Text>
                    <Text fontSize="sm">{`Responsável: ${item.responsible}`}</Text>
                  </Box>
                  
                </Box>
              </ListItem>
            ))}
          </List>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme='teal' mr={3} onClick={() => setOpenHistory(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Home;