import Types from './types';

const INITIAL_STATE = {
  loading: false,
  error: false,
  eventList: [{}],
  userList: [{}]
}

// Reducer
export default function slaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CREATE_EVENT:
      return {
        ...state,
        loading: true,
      }
    case Types.CREATE_EVENT_CONCLUDED:
      return {
        ...state,
        loading: false,
      }
    case Types.CREATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case Types.GET_EVENTS:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_EVENTS_COMPLETED:
      return {
        ...state,
        loading: false,
        eventList: action.payload.eventList
      }

    case Types.GET_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case Types.GET_USERS:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_USERS_COMPLETED:
      return {
        ...state,
        loading: false,
        userList: action.payload.userList
      }

    case Types.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state;
  }
}