import React, { useEffect, useState, memo, useRef } from "react";
import moment from 'moment';
import {
  Box,
  Textarea,
  Input,
  Button,
  Text,
  RadioGroup,
  Radio,
  Stack,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'
import { Creators } from "../../store/sla/actions";
import { db } from "../../config/firebase"
import { useDispatch } from 'react-redux';
import DatePicker from '../../Components/DatePicker';
import InputMask from "react-input-mask";
import { setorOptions } from "../../utils/setorOptions";

const AddEventModal = ({onClose, isOpen, itemToEdit, loading, currentUser}) => {
  const dispatch = useDispatch();
  const interestNameRef = useRef();
  const interestAddressRef = useRef();
  const interestCpfCnpjRef = useRef();
  const interestPhoneRef = useRef();
  const interestEmailRef = useRef();
  const [embargo, setEmbargo] = useState(itemToEdit ? itemToEdit.embargo : 'NÃO');
  const [exigenciaCumprida, setExigenciaCumprida] = useState(itemToEdit ? itemToEdit.exigenciaCumprida : 'NÃO');
  const [vencDate, setVencDate] = useState(moment(new Date()).format('DD/MM/YYYY'));
  const [prazo, setPrazo] = useState(undefined);
  const [eventSection, setEventSection] = useState(itemToEdit? itemToEdit?.eventSection : '');
  const [eventDate, setEventDate] = useState(itemToEdit?.eventDate || new Date());
  const eventDescriptionRef = useRef();
  const eventNumberRef = useRef()
  const [eventType, setEventType] = useState(itemToEdit? itemToEdit?.eventType : '');

  const handleSelectDate = (date) => {
    setVencDate(moment(new Date(date)).format('DD/MM/YYYY'));
    setEventDate(date);
  }

  const handleClose = () => {
    onClose();
  };

  const handleCreateEvent = () => {
    const userName = sessionStorage.getItem('user-name');
    dispatch(Creators.createEvents({
      eventType,
      eventSection,
      eventDate: moment(new Date(eventDate)).toISOString(),
      timestamp: moment(eventDate).valueOf(),
      eventDescription: eventDescriptionRef.current.value,
      licenseNumber: eventNumberRef.current.value,
      embargo,
      exigenciaCumprida,
      userId: currentUser.uid,
      userName,
      interestAddress: interestAddressRef.current.value,
      interestCpfCnpj: interestCpfCnpjRef.current.value,
      interestName: interestNameRef.current.value,
      interestPhone: interestPhoneRef.current.value,
      interestEmail: interestEmailRef.current.value,
      currentResponsibleName: sessionStorage.getItem('user-name'),
      currentResponsibleId: currentUser.uid,
      history: [{
        responsible: sessionStorage.getItem('user-name'),
        section: eventSection,
        date: moment().format(),
      }],
    }));
    handleClose();
  };

  const handleEditEvent = async () => {
    await db.collection('events').doc(itemToEdit.id).set({
      eventType,
      eventDate: moment(new Date(eventDate)).toISOString(),
      eventDescription: eventDescriptionRef.current.value,
      licenseNumber: eventNumberRef.current.value,
      eventSection,
      interestAddress: interestAddressRef.current.value,
      embargo,
      exigenciaCumprida,
      interestCpfCnpj: interestCpfCnpjRef.current.value,
      interestName: interestNameRef.current.value,
      interestPhone: interestPhoneRef.current.value,
      interestEmail: interestEmailRef.current.value,
    }, { merge: true }).then(
      handleClose()
    );
    
  };

  useEffect(() => {
    if (prazo !== undefined) {
      const newDate = moment().add(prazo, 'days').toISOString();
      const venc = moment(newDate).format('DD/MM/YYYY');
      setVencDate(venc)
      setEventDate(new Date(newDate));
    }
  }, [prazo])

  useEffect(() => {
    if(itemToEdit) {
      setEventDate(new Date(itemToEdit?.eventDate))
      setEventSection(itemToEdit.eventSection)
      setEventType(itemToEdit.eventType)
    } 
  }, [itemToEdit])

  return (
    <Modal size="xl" isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {itemToEdit ? (
              'Editar Evento'
            ) : (
              'Criar Novo Evento'
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text marginBottom="20px">
              O Novo evento ficará disponível na agenda para todos os usuários do sistema.
            </Text>
            <Text fontSize="md" marginBottom="5px">Número do processo</Text>
            <Input 
              placeholder='Número do processo'
              ref={eventNumberRef}
              defaultValue={itemToEdit ? itemToEdit.licenseNumber : ''}
            />
            <Text fontSize="md" marginBottom="5px">Setor</Text>
            <Select 
              placeholder="Selecione o setor" 
              size="md"
              onChange={(e) => setEventSection(e.target.value)}
              value={itemToEdit ? itemToEdit.eventSection : eventSection}
              disabled={itemToEdit}
              marginBottom="10px"
            >
              <option value="Ministério Público">Ministério Público</option>
              <option value="Setor de Fiscalização">Setor de Fiscalização</option>
              <option value="Setor Técnico">Setor Técnico</option>
              <option value="Setor Jurídico">Setor Jurídico</option>
              <option value="Setor Administrativo">Setor Administrativo</option>
            </Select>
            <Text fontSize="md" marginBottom="5px">Tipo de evento</Text>
            <Select 
              placeholder="Selecione o tipo de evento" 
              size="md"
              onChange={(e) => setEventType(e.target.value)}
              value={itemToEdit ? itemToEdit.eventType : eventType}
              disabled={itemToEdit}
            >
              {Object.keys(setorOptions).map(function(key, index) {
                if (key === eventSection) {
                  return setorOptions[key].map((value) => (
                    <option value={value}>{value}</option>
                  ))
                }
                return '';
              })}
             
            </Select>  
            <Text fontSize="md" marginBottom="5px" marginTop="10px">
              Descrição
            </Text>
            <Textarea
              defaultValue={itemToEdit ? itemToEdit.eventDescription : ''}
              ref={eventDescriptionRef}
              placeholder="Digite aqui a descricão do evento se necessário"
              size="md"
            />
            {(eventSection === 'Setor de Fiscalização' || itemToEdit?.eventSection === 'Setor de Fiscalização') && (
              <>
                <Text fontSize="md" marginBottom="5px" marginTop="10px">Termo de embargo</Text>
                <RadioGroup onChange={setEmbargo} value={embargo}>
                  <Stack spacing={4} direction='row'>
                    <Radio colorScheme='teal' value='SIM'>Sim</Radio>
                    <Radio colorScheme='teal' value='NÃO'>Não</Radio>
                    
                  </Stack>
                </RadioGroup>
              </>
            )}
            {(eventSection === 'Setor Técnico' || itemToEdit?.eventSection === 'Setor Técnico') && (
              <>
                <Text fontSize="md" marginBottom="5px" marginTop="10px">Exigência cumprida</Text>
                <RadioGroup onChange={setExigenciaCumprida} value={exigenciaCumprida}>
                  <Stack spacing={4} direction='row'>
                    <Radio colorScheme='teal' value='SIM'>Sim</Radio>
                    <Radio colorScheme='teal' value='NÃO'>Não</Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}
            <Text fontSize="md" marginBottom="5px" marginTop="10px">Dados do interessado</Text>
            <Input 
              placeholder='Nome'
              marginBottom="5px"
              ref={interestNameRef}
              defaultValue={itemToEdit ? itemToEdit.interestName : ''}
            />
            <Input 
              placeholder='Endereço'
              marginBottom="5px"
              ref={interestAddressRef}
              defaultValue={itemToEdit ? itemToEdit.interestAddress : ''}
            />
            <Input 
              placeholder='CPF/CNPJ'
              marginBottom="5px"
              ref={interestCpfCnpjRef}
              defaultValue={itemToEdit ? itemToEdit.interestCpfCnpj : ''}
            />
            <Input 
              placeholder='Telefone'
              marginBottom="5px"
              ref={interestPhoneRef}
              defaultValue={itemToEdit ? itemToEdit.interestPhone : ''}
            />
            <Input 
              placeholder='Email'
              marginBottom="5px"
              ref={interestEmailRef}
              defaultValue={itemToEdit ? itemToEdit.interestEmail : ''}
            />
            {eventSection !== 'Setor Administrativo' ? (
              <>
                <Text fontSize="md" marginBottom="5px" marginTop="10px">Prazo (em dias)</Text>
                <NumberInput min={1} clampValueOnBlur={false}>
                  <NumberInputField onChange={(e) => setPrazo(e.target.value)} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                
              </>
            ) : (
              <></>
            )}
            <Text fontSize="md" marginBottom="5px" marginTop="10px">
              {eventType === 'Vencimento de Licença' ? 'Data de vencimento da licença' : 'Data do evento'}
            </Text>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Input 
                as={InputMask} 
                mask="**/**/****"
                value={itemToEdit ? moment(itemToEdit.eventDate).format('DD/MM/YYYY') : moment(eventDate).format('DD/MM/YYYY')}
                maskChar={'_'} 
                placeholder='Digite data de vencimento'
                marginBottom="10px"
                marginRight="5px"
                maxWidth="275px"
                onChange={(e) => setVencDate(e.target.value)}
              />
              <IconButton
                variant='outline'
                size="sm"
                colorScheme='teal'
                aria-label='Set Date'
                icon={<CheckIcon />}
                marginTop="-10px"
                onClick={() => {
                  const _date = moment(vencDate, 'DD/MM/YYYY').toISOString();
                  setEventDate(new Date(_date))
                }}
              />
            </Box>
            
            <DatePicker 
              // disablePastDates={pastDates}
              handleSelectDate={handleSelectDate}
              date={eventDate}
            />
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="outline" colorScheme="teal" mr={3} onClick={onClose}>
              Fechar
            </Button>
            {itemToEdit ? (
                <Button 
                  onClick={() => handleEditEvent()} 
                  colorScheme="teal"
                  size="sm"
                  loading={loading}
                >
                  Editar
                </Button>
            ) : (
              <Button 
                onClick={() => handleCreateEvent()} 
                colorScheme="teal"
                size="sm"
                loading={loading}
              >
              Criar
            </Button>
            )}
            
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default memo(AddEventModal)