import React, { useContext, useState, useEffect } from "react"
import { auth } from "../config/firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);

  function signup(email, password, name) {
    return auth.createUserWithEmailAndPassword(email, password)
    .then(function(result) {
      return result.user.updateProfile({
        displayName: name
      })
    }).catch(function(error) {
      console.log(error);
    });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function signOut() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!!user) {
        let data = user.toJSON();
        setCurrentUser(data);
        setLoading(false);
      } else {
        setCurrentUser(user);
        sessionStorage.setItem('user-role', '');
        sessionStorage.setItem('user-name', '');
        setLoading(false);
      }
    })

    return unsubscribe
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userRole = sessionStorage.getItem('user-role');
  const userName = sessionStorage.getItem('user-name');
  const value = {
    currentUser,
    setCurrentUser,
    userRole,
    userName,
    login,
    signup,
    signOut,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
