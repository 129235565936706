import Types from './types';

// Action Creators
export const Creators = {
  createEvents: (values) => ({ 
    type: Types.CREATE_EVENT,
    payload: values
  }),

  getAllEvents: () => ({
    type: Types.GET_EVENTS,
  }),

  getUsers: () => ({
    type: Types.GET_USERS,
  })

}