import React from 'react';
import { useHistory } from 'react-router'
import { useAuth } from '../../contexts/Auth'
import { HamburgerIcon, AddIcon, CalendarIcon, ArrowBackIcon, CopyIcon, StarIcon } from '@chakra-ui/icons';
import Logo from '../../assets/SLAdigitalWhite.png'
import { Center } from '@chakra-ui/layout';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  Box,
  Text
} from "@chakra-ui/react";

const Navigation = () => {
  const { signOut } = useAuth();
  const history = useHistory();
  const role = sessionStorage.getItem('user-role');
  const userName = sessionStorage.getItem('user-name');

  async function handleSignOut() {
    await signOut();
    sessionStorage.setItem('user-id', undefined);
    history.push('/login');
  };

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        background: '#2D807F'
        }}
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          <MenuItem icon={<CalendarIcon />} onClick={() => history.push('/')}>
            Agenda
          </MenuItem>
          <MenuItem icon={<AddIcon />} onClick={() => history.push('/adicionarLicenca')} >
            Criar nova licença 
          </MenuItem>
          <MenuItem icon={<CopyIcon />} onClick={() => history.push('/licencas')}>
            Todas as Licenças 
          </MenuItem>
          {role === 'admin' && (
            <MenuItem icon={<StarIcon />}>
              Usuários
            </MenuItem>
          )}
          <MenuItem icon={<ArrowBackIcon />} onClick={handleSignOut}>
            Sair 
          </MenuItem>
        </MenuList>
      </Menu>
      <Center>
        <Image
          blockSize="80px"
          objectFit="cover"
          src={Logo}
          alt="logoGIF"
          marginTop="-25px"
          marginLeft="45px"
          onClick={() => history.push('/')}
        />
      </Center>
      <Box >
        <Text color="#fff">{`Olá ${userName}`}</Text>
      </Box>
    </div>
  )
}

export default Navigation;