import { useState } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Calendar, CalendarDefaultTheme } from '@uselessdev/datepicker';
import pt from 'date-fns/locale/pt';
import { registerLocale } from 'react-datepicker';
registerLocale('pt', pt)

export const theme = extendTheme(CalendarDefaultTheme, {
  components: {
    Calendar: {
      parts: ['calendar'],

      baseStyle: {
        calendar: {
          borderWidth: '1px',
          borderColor: 'teal',
          rounded: '10px',
          shadow: 'none',
        },
      },
    },

    CalendarControl: {
      parts: ['button'],

      baseStyle: {
        button: {
          h: 6,
          px: 2,
          rounded: '10px',
          fontSize: 'sm',
          color: 'white',
          bgColor: 'teal',

          _hover: {
            bgColor: 'teal',
          },

          _focus: {
            outline: 'none',
            bgColor: 'teal',
          },
        },
      },
    },
    CalendarDay: {
      parts: ['day'],
      variants: {
        selected: {
          bgColor: 'teal',
          _hover: {
            bgColor: '#96bfbf',
          },
        },
        
      }
    }
  },
})

const DatePicker = (props) => {
  const { disablePastDates, handleSelectDate, date } = props;

  return (
    <ChakraProvider theme={theme}>
      <Calendar
        value={{ start: date }}
        singleMonth
        singleDateSelection
        onSelectDate={handleSelectDate}
        locale={pt}
        disablePastDates={disablePastDates}
      />
    </ChakraProvider>
  )
};

export default DatePicker;
