import React, {useState} from 'react';
import {
  Box,
  Text,
  Heading,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverArrow,
  Button,
  Tooltip,
  Select
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, TimeIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import moment from 'moment';
import pt from 'date-fns/locale/pt';
import { registerLocale } from 'react-datepicker';
registerLocale('pt', pt)

const mapDay = [
  'DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB',
];

const EventItem = (props) => {
  const { 
    item, 
    currentUser, 
    handleEdit, 
    handleDelete, 
    setorOptions, 
    userList,
    handleSend,
    handleHistory,
    key
  } = props;
  const [eventSection, setEventSection] = useState();
  const [eventType, setEventType] = useState();
  const [responsible, setResponsible] = useState();
  const day = moment(item.eventDate).isoWeekday();
  const userRole = sessionStorage.getItem('user-role')

  const getColor = () => {
    if (item.eventSection === 'Setor Administrativo') {
      return '#F2CC8F';
    }
    if (item.eventSection === 'Setor Técnico') {
      return '#BADEFC';
    }
    if (item.eventSection === 'Ministério Público') {
      return '#F45B69';
    }
    if (item.eventSection === 'Setor de Fiscalização') {
      return '#E5D352';
    }
    if (item.eventSection === 'Setor Jurídico') {
      return '#BCD8C1';
    }
    return '#F2F4F3';
  }

  return (
    <Box
      key={key}
      padding="10px"
      borderWidth="1px" 
      borderRadius="lg"
      width="100%"
      borderColor="#96bfbf"
      marginBottom="10px"
      bgColor={getColor()}
      shadow='md'
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex"
      flexDirection="row">
        <Box
          maxWidth="60px"
          minWidth="60px"
          flexDirection="column"
          display="flex"
          borderRight="1px"
          borderColor="#96bfbf"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="teal">{moment(item.eventDate).format('DD/MM')}</Text>
          <Text color="gray">{mapDay[day]}</Text>
        </Box>
        <Box 
          marginLeft="10px" 
          maxWidth="400px"
          borderRight="1px"
          borderColor="#96bfbf"
          marginRight="5px"
          paddingRight="5px"
        >
          <Heading size="sm">
            {item.eventSection}
          </Heading>
          <Heading size="sm">
            {item.eventType}
          </Heading>
          
          <Text>{`Nº processo: ${item.licenseNumber}`}</Text>
          <Text>{`Responsável: ${item.currentResponsibleName}`}</Text>
          
          <Text>{item.eventDescription}</Text>
          {item.eventSection === 'Setor de Fiscalização' && (
            <Text>{`Termo de embargo: ${item.embargo}`}</Text>
          )}
          {item.eventSection === 'Setor Técnico' && (
            <Text>{`Exigência Cumprida: ${item.exigenciaCumprida}`}</Text>
          )}
          <Text fontSize="xs" color="gray">
            {`Criada por ${item.userName} - ${moment(item.createdAt).format('DD/MM/YYYY')}`}
          </Text>
        </Box>
        <Box 
          marginLeft="5px" 
          maxWidth="400px"
        >
          <Text fontSize="lg">Dados do interessado</Text>
          <Text>{`Nome: ${item.interestName} - CPF/CNPJ: ${item.interestCpfCnpj}`}</Text>
          <Text>{`Endereço: ${item.interestAddress}`}</Text>
          <Text>{`Contato: ${item.interestPhone}`}</Text>
          <Text>{`Email: ${item.interestEmail}`}</Text>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Tooltip hasArrow label='Histórico' bg='gray.300' color='black'>
          <IconButton 
            size="sm" 
            aria-label='history' 
            icon={<TimeIcon />} 
            variant='outline'
            colorScheme='teal'
            marginRight="10px"
            onClick={() => handleHistory(item.history)}
          />
        </Tooltip>
        {currentUser && (currentUser.uid === item.userId || currentUser.uid === item.currentResponsibleId || userRole === 'controller') && (
          <>
            <Popover placement='left'>
              <PopoverTrigger>
                <IconButton 
                  size="sm" 
                  aria-label='send' 
                  icon={<ExternalLinkIcon />} 
                  variant='outline'
                  colorScheme='teal'
                  marginRight="10px"
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Encaminhar evento?</PopoverHeader>
                <PopoverBody>
                  <Box>
                    <Text marginBottom="5px">Essa ação ficará salva no histórico permanentemente.</Text>
                  
                    <Text fontSize="md" marginBottom="5px">Setor</Text>
                    <Select 
                      placeholder="Selecione o setor" 
                      size="md"
                      onChange={(e) => setEventSection(e.target.value)}
                      value={eventSection}
                      marginBottom="10px"
                    >
                      <option value="Ministério Público">Ministério Público</option>
                      <option value="Setor de Fiscalização">Setor de Fiscalização</option>
                      <option value="Setor Técnico">Setor Técnico</option>
                      <option value="Setor Jurídico">Setor Jurídico</option>
                      <option value="Setor Administrativo">Setor Administrativo</option>
                    </Select>
                    <Text fontSize="md" marginBottom="5px">Tipo de evento</Text>
                    <Select 
                      placeholder="Selecione o tipo de evento" 
                      size="md"
                      marginBottom="5px"
                      onChange={(e) => setEventType(e.target.value)}
                      value={eventType}
                      disabled={!eventSection}
                    >
                      {Object.keys(setorOptions).map(function(key, index) {
                        if (key === eventSection) {
                          return setorOptions[key].map((value) => (
                            <option value={value}>{value}</option>
                          ))
                        }
                        return '';
                      })}
                    
                    </Select>
                    <Text fontSize="md" marginBottom="5px">Responsável</Text>
                    <Select 
                      placeholder="Selecione o responsável" 
                      size="md"
                      onChange={(e) => setResponsible(e.target.value)}
                      value={responsible}
                      disabled={!eventSection}
                    >
                      {userList.map((item) => {
                        return <option value={item.id}>{item.name}</option>
                      })}
                    
                    </Select>
                    <Button
                      size="sm"
                      colorScheme='teal'
                      variant='solid'
                      marginTop="10px"
                      onClick={() => handleSend(item, item.id, eventSection, eventType, responsible)}
                    >
                      Encaminhar
                    </Button>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Tooltip hasArrow label='Editar' bg='gray.300' color='black'>
              <IconButton 
                size="sm" 
                aria-label='Edit' 
                icon={<EditIcon />} 
                variant='outline'
                colorScheme='teal'
                marginRight="10px"
                onClick={() => {
                  handleEdit(item)
                }}
              />
            </Tooltip>
          </>
        )}
        {currentUser && (currentUser.uid === item.userId || currentUser.uid === item.currentResponsibleId) && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                size="sm" 
                aria-label='Delete' 
                icon={<DeleteIcon />} 
                variant='outline'
                colorScheme='teal'
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Deletar evento?</PopoverHeader>
              <PopoverBody>
                Tem certeza que deseja deletar este evento?
                <Button
                  size="sm"
                  colorScheme='teal'
                  variant='solid'
                  marginLeft="10px"
                  onClick={() => handleDelete(item.id)}
                >
                  Deletar
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    </Box>
  )
}

//others colors
// #A9927D
// #F45B69
// #E5D352
// #222E50
// #BCD8C1
// #E9D985
// #C2948A

export default EventItem;