import CUOStemplate from '../../templates/templateUsoDoSolo.docx';


export const licensesInfo = {
  'CUOS': {
    file: CUOStemplate,
    description: 'A Secretaria Municipal de Meio Ambiente e dos Recursos Hídricos no uso da competência de que trata a Lei nº 2991 de 03 de outubro de 2006 (Plano Diretor de Luziânia), obedecidas as disposições constantes na Lei 3.021 de 26 de dezembro de 2006 (Código Ambiental de Luziânia), sendo portanto, de USO PERMISSÍVEL, para esta finalidade, concede a presente Certidão:',
    obsList: [
      {id: 1, isChecked: true, description: 'Por se tratar de uma atividade potencialmente poluidora, o requerente deverá solicitar o(s) Licenciamento(s) Ambiental(is) pertinentes à atividade pleiteada para dar continuidade ao processo.'},
      {id: 2, isChecked: true, description: 'A presente certidão de uso e ocupação do solo está sendo concedido com base nas informações constantes no Processo Administrativo, e não dispensa e nem substitui outros, outros alvarás ou certidões exigidas pelas legislações ambientais vigentes;'},
      {id: 3, isChecked: true, description: 'A SEMARH/LUZ deverá ser comunicada imediatamente em caso de acidentes que envolvam o Meio Ambiente;'},
      {id: 4, isChecked: true, description: 'A instalação do empreendimento não poderá causar transtornos ao meio ambiente e/ou a terceiros fora da área de sua propriedade ou fora dela;'},
      {id: 5, isChecked: true, description: 'Esta Certidão não produz efeitos de cessão e/ou aquisição sobre direito de propriedade, de posse, de uso, de usufruto; de servidão e demais direitos inerentes à propriedade móvel e imóvel sobre a área e bens delimitados; nem mesmo direito adquirido, produzindo somente efeitos jurídicos nos limites da legislação ambiental'},
    ],
    areaFields: false,
    vistoriaFileds: true,
  },
  'LAP': {
    file: CUOStemplate,
    description: 'A Secretaria Municipal de Meio Ambiente e dos Recursos Hídricos de Luziânia – SEMAR/LUZ, no uso de suas atribuições que lhe foram conferidas pela Lei Municipal nº 2.595/2003, Resoluções CEMAm nº 077/2007 e nº107/2021 e a Lei Nº3.021 de 26 de dezembro de 2006 que institui o Código Ambiental de Luziânia, concede a presente Licença Ambiental Prévia nas condições especificadas a seguir:',
    obsList: [
      {id: 1, isChecked: true, description: 'A presente certidão de uso e ocupação do solo está sendo concedido com base nas informações constantes no Processo Administrativo n 2021031654, e não dispensa e nem subsitui outros, outros alvarás ou certidões exigidas pelas legislações ambientais vigentes;'},
      {id: 2, isChecked: true, description: 'A SEMARH/LUZ deverá ser comunicada imediatamente em caso de acidentes que envolvam o Meio Ambiente;'},
      {id: 3, isChecked: true, description: 'A instalação do empreendimento não poderá causar transtornos ao meio ambiente e/ou a terceiros fora da área de sua propriedade ou fora dela;'},
      {id: 4, isChecked: true, description: 'A SEMARH/LUZ reserva-se no direito de revogar a presente licença no caso de descumprimento descondicionantes, ou de qualquer dispositivo que fira a Legislação Ambiental vigente, assim como a omissão ou falsa descrição de informações relevantes que subsidiam a sua expedição, ou superveniência deste órgão.'},
    ],
    areaFields: true,
    vistoriaFileds: false,
  }
}