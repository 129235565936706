const Types = {
  CREATE_EVENT: 'sla/CREATE_EVENT',
  CREATE_EVENT_CONCLUDED: 'sla/CREATE_EVENT_CONCLUDED',
  CREATE_EVENT_ERROR: 'sla/CREATE_ERROR',
  GET_EVENTS: 'sla/GET_EVENTS',
  GET_EVENTS_COMPLETED: 'sla/GET_EVENTS_COMPLETED',
  GET_EVENTS_ERROR: 'sla/GET_EVENTS_ERROR',
  GET_USERS: 'sla/GET_USERS',
  GET_USERS_COMPLETED: 'sla/GET_USERS_COMPLETED',
  GET_USERS_ERROR: 'sla/GET_USERS_ERROR',
};

export default Types;
