import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';
import { db } from '../../config/firebase';
import moment from 'moment';
import Types from './types';

async function dbFetchEvents() {
  const response = await db.collection('events')
  .orderBy('eventDate', 'asc')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  console.log(response);
  return response;
}

function* fetchEvents() {
  try {
    const data = yield call(dbFetchEvents);
    yield put({
      type: Types.GET_EVENTS_COMPLETED,
      payload: {
        eventList: data
      }
    })
  } catch (err) {
    console.log(err);
    yield put({
      type: Types.GET_EVENTS_ERROR,
      payload: err?.response?.error || true,
    });
  }
}

async function dbFetchUsers() {
  const response = await db.collection('users')
  .orderBy('role', 'asc')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response;
}

function* fetchUsers() {
  try {
    const data = yield call(dbFetchUsers);
    yield put({
      type: Types.GET_USERS_COMPLETED,
      payload: {
        userList: data
      }
    })
  } catch (err) {
    console.log(err);
    yield put({
      type: Types.GET_USERS_ERROR,
      payload: err?.response?.error || true,
    });
  }
}

async function dbCreateEvent(params) {
  const { 
    eventType,
    eventSection,
    eventDescription, 
    userId, 
    eventDate,
    licenseNumber,
    embargo,
    userName,
    interestAddress,
    interestCpfCnpj,
    interestName,
    interestPhone,
    interestEmail,
    exigenciaCumprida,
    currentResponsibleName,
    currentResponsibleId,
    history,
    timestamp
  } = params;

  const ref = db.collection('events').doc();
  const myId = ref.id;
  await db.collection('events').doc(myId).set({
    id: myId,
    eventType,
    eventSection,
    eventDescription, 
    userId, 
    eventDate,
    licenseNumber,
    userName,
    exigenciaCumprida,
    interestAddress,
    interestCpfCnpj,
    interestName,
    interestPhone,
    interestEmail,
    embargo,
    currentResponsibleName,
    currentResponsibleId,
    history,
    timestamp,
    createdAt: moment().format()
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
}

function* handleCreateEvent({ payload }) {
  try {
    yield call(dbCreateEvent, payload);

    yield put({
      type: Types.CREATE_EVENT_CONCLUDED,
    });

  } catch (err) {
    console.log(err);
    yield put({
      type: Types.CREATE_EVENT_ERROR,
      payload: err?.response?.error || true,
    });
  }
};


export function* slaSagas() {
  yield takeLatest(Types.CREATE_EVENT, handleCreateEvent);
  yield takeLatest(Types.GET_EVENTS, fetchEvents);
  yield takeLatest(Types.GET_USERS, fetchUsers);
}