import axios from "axios";

axios.defaults.headers.get['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

const apiCnpj = axios.create({
  baseURL: "https://cors-anywhere.herokuapp.com/https://www.receitaws.com.br/v1/cnpj",
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET ,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  },
});

export default apiCnpj;