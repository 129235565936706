import React from 'react';
import {
  Heading, Box,
} from '@chakra-ui/react';

import { Center } from '@chakra-ui/layout';
import Navigation from '../../Components/Nabvigation';
/* import moment from 'moment';
import { useHistory } from 'react-router';
import { isAfter, isBefore } from 'date-fns';
import DatePicker from '../../Components/DatePicker';
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../store/sla/actions';
import { db } from '../../config/firebase'

import { AddIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@chakra-ui/icons'
import { useAuth } from '../../contexts/Auth';

import EventItem from '../../Components/EventItem';
import { isEmpty } from 'lodash';
import LicenseTable from './LicenseTable';

const columns = [
  {
    columns: [
      {
        Header: "id",
        accessor: "id",
        columnId: 1
      },
      {
        Header: "Número do processo",
        accessor: "licenseNumber",
        columnId: 2
      },
      {
        Header: "Tipo",
        accessor: "licenseType",
        columnId: 3
      },
      {
        Header: "Requerente",
        accessor: "applicantName",
        columnId: 4
      },
      {
        Header: "Vencimento",
        accessor: "expirationDate",
        columnId: 5
      },
      {
        Header: "Criado em",
        accessor: "createdAt",
        columnId: 6
      },
    ]
  },
]; */

/* const data = [
  {
    id: "1",
    licenseNumber: "324524345",
    licenseType: "MEI",
    applicantName: "José",
    expirationDate: "22/10/2022",
    createdAt: "12/01/2022"
  },
  {
    id: "1",
    licenseNumber: "324524345",
    licenseType: "MEI",
    applicantName: "José",
    expirationDate: "22/10/2022",
    createdAt: "12/01/2022"
  },

  {
    id: "1",
    licenseNumber: "324524345",
    licenseType: "MEI",
    applicantName: "José",
    expirationDate: "22/10/2022",
    createdAt: "12/01/2022"
  },

  {
    id: "1",
    licenseNumber: "324524345",
    licenseType: "MEI",
    applicantName: "José",
    expirationDate: "22/10/2022",
    createdAt: "12/01/2022"
  },

  {
    id: "1",
    licenseNumber: "324524345",
    licenseType: "MEI",
    applicantName: "José",
    expirationDate: "22/10/2022",
    createdAt: "12/01/2022"
  },


]; */

const Licenses = () => {
  /* const { currentUser } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => {
    return state.slaReducer;
  }); */


  return (
    <>
      <Navigation />
      <Center marginTop="10px">
        <Heading size="lg">Todas as Licenças</Heading>
      </Center>
      <Box 
        margin="20px"
        minHeight="400px"
        padding="10px"
        borderWidth="1px" 
        borderRadius="lg"
        display="flex"
        flexDirection="column"
      >
        <Box 
          display="flex"
          flexDirection="row"
          height="30px"
           marginTop="10px"
          paddingLeft="20px"
          paddingRight="20px"
          marginBottom="20px"
        >
         <h3>TABELA</h3>
      </Box>
      </Box>
    </>
  )
}

export default Licenses;