import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router';
import { Center, VStack } from '@chakra-ui/layout';
import Video from '../../assets/SLAdigital.gif';
import { 
  InputGroup, 
  Input, 
  InputLeftElement, 
  InputRightElement, 
  Button,
  Image,
  useToast,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ArrowForwardIcon, UnlockIcon } from '@chakra-ui/icons';
import { useAuth } from '../../contexts/Auth';

const Login = () => {
  const { login, resetPassword, currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [recoveryEmail, setRecoveryEmail] = useState(undefined);
  const [password, setPassword] = useState();
  const handleClick = () => setShow(!show);

  const validateEmail = (email) => {
    if (email === undefined) return true;
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleResetPassword = () => {
    resetPassword(recoveryEmail).then(function() {
      toast({
        title: "Email de recuperação enviado",
        description: "Verifique seu email e clique no link",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    }).catch(function(error) {
      toast({
        title: "Email não cadastrado",
        description: "Consulte o administrador do sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    })
  }

  async function handleSubmit() {
    setLoading(true);
    // Calls `signIn` function from the context
    try {
      const { error } = await login(email, password);
      console.log(error);
      setLoading(false);
      // Redirect user
      history.push('/')
    } catch (err) {
      setLoading(false);
      toast({
        title: "Credencial de acesso inválida.",
        description: "Tente novamente",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (currentUser !== undefined && currentUser !== null) {
      history.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <Center>
      <VStack spacing={2} marginTop="10%">
        <Center>
          <Image
            objectFit="cover"
            border="full"
            src={Video}
            alt="logoGIF"
          />
        </Center>
        <Center w="400px" flexDirection="column">
          <VStack spacing={4}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="gray.300" />}
              />
              <Input 
                type="email"
                isInvalid={!validateEmail(email)}
                placeholder="Email" 
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <InputLeftElement
                pointerEvents="none"
                children={show ? <UnlockIcon color="gray.300" /> : <LockIcon color="gray.300" />}
              />
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Digite a senha"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button marginRight="5px" h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Esconder" : "Mostrar"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </Center>
        <Link marginBottom="20px" color="blue.200" onClick={() => onOpen()}>
          Esqueceu a senha?
        </Link>
        <Button 
          rightIcon={<ArrowForwardIcon />}
          colorScheme="teal"
          isLoading={loading}
          loadingText="Entrando"
          onClick={() => handleSubmit()}
        >
          Entrar
        </Button>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solicitar nova senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text marginBottom="20px">
              Para solicitar uma nova senha digite seu e-mail e o código de acesso da secretaria.
              Após solicitar verifique seu e-mail.
            </Text>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="gray.300" />}
              />
              <Input 
                type="tel"
                isInvalid={!validateEmail(recoveryEmail)}
                placeholder="Email" 
                onChange={(e) => setRecoveryEmail(e.target.value)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" colorScheme="teal" mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button 
              onClick={() => handleResetPassword()} 
              colorScheme="teal"
              disabled={!validateEmail(recoveryEmail)}
            >
              Solicitar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  )
}

export default Login;